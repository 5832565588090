<template>
  <div
    :class="[ 'button', `button-${type}`, buttonClass ]"
    @click.prevent="onClick"
  >
    <div
      :class="[ 'button-inner', labelClass, { icon } ]"
    >
      <div v-if="icon" :class="[ 'icon-img', iconClass ]"></div>
      <span v-html="$getString(label)"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
    },
    buttonClass: {
      type: String,
      required: false,
      default: '',
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    action: {
      type: Object,
      required: false,
      default: null,
    }
  },
  computed: {
    iconClass() {
      return this.icon !== '' ? `icon icon-${this.icon}` : '';
    },
  },
  methods: {
    onClick() {
      this.$emit('pressed', this.action);
    }
  },
}
</script>
