import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    settings: {},
    strings: {},
    screens: [],
    stages: [],
    references: [],

    conditions: {},

    quiz: [],
    score: 100,
    feedback: [],

    availableSections: [ 0 ],
  },
  getters: {
    strings: state => state.strings,
    string: state => key => {
      let string = (key in state.strings) ? state.strings[key] : '';
      if (!string) {
        console.warn(`String not found [${key}]`);
        string = key;
      }
      return string;
    },
    settings: state => state.settings,
    setting: state => key => state.settings[key],
    screens: state => state.screens,
    stages: state => state.stages,
    stage: state => index => state.stages[index],
    references: state => items => {
      const refs = [];
      items.forEach(index => refs.push(state.references[index]));
      return refs;
    },
    conditions: state => state.conditions,
    condition: state => key => state.conditions[key],
    
    score: state => state.score,

    availableSections: state => state.availableSections,
  },
  mutations: {
    setSettings: (state, settings) => state.settings = settings,
    setStrings: (state, strings) => state.strings = strings,
    setScreens: (state, screens) => state.screens = screens,
    setStages: (state, stages) => state.stages = stages,
    setReferences: (state, references) => state.references = references,

    setCondition: (state, condition) => state.conditions[condition.key] = condition.value,

    initQuizAnswers: (state) => state.quiz = [],
    setQuizAnswers: (state, answers) => state.quiz = answers,
    
    initFeedback: (state, feedback) => state.feedback = feedback,
    setFeedback: (state, question, choice) => state.feedback[question] = choice,
    
    resetScore: (state) => state.score = 100,
    subtractScore: (state, scoremod) => {
      state.score += scoremod;
      if (state.score < 50) {
        state.score = 50;
      }
    },
    
    resetSections: (state) => state.availableSections = [ 0 ],
    enableSections: (state, section) => {
      if (state.availableSections.indexOf(section) < 0) {
        state.availableSections.push(section);
      }
    },
  }
})
