<template>
  <ul :class="classes">
    <li v-for="(item, index) in items" :key="index" v-html="item"></li>
  </ul>
</template>

<script>
export default {
  name: 'PopupText',
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    }
  },
}
</script>
