<template>
  <div class="section-row">
    <ul>
      <li 
        v-for="(item, index) in data.value"
        :key="index"
        v-html="item"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RecordList',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
