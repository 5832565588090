<template>
  <div :class="[ 'popup-inner', popup.class ]">
    <div class="h-fill flex flex-column align-items-center justify-content-center">
      <h1
        v-if="popup.heading"
        class="fg-purple"
        v-html="$getString(popup.heading)"
      ></h1>

      <template
        v-for="(item, index) in popup.items"
      >
        <PopupText v-if="item.type === 'text'" :key="index" :lines="item.value" classes="text-center" />
      </template>
    </div>

    <div class="popup-buttons">
      <DefaultButton
        v-for="(button, index) in popup.buttons"
        :key="index"
        :label="button.label"
        labelClass="text-uppercase"
        type="popup"
        @pressed="onPressed(button)"
      />
    </div>

    <slot></slot>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton'
import PopupText from '../partials/PopupText'

export default {
  name: 'PopupScore',
  components: {
    DefaultButton,
    PopupText,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      heading: '',
      lines: [],
      score: 0,
    }
  },
  methods: {
    onPressed(event) {
      this.$emit('action', event);
    }
  },
  mounted() {
    this.score = this.$store.getters.score;
  }
}
</script>
  