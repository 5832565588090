<template>
  <div v-if="popupComponent" class="popup background-popup">
    <component
      :is="popupComponent"
      :key="key"
      :popup="popup"
      @action="onAction"
    >
      <div
        v-if="popup.references && popup.references.length > 0"
        class="references-button"
        @click.prevent="showReferences"
      ></div>
    </component>
  </div>
</template>

<script>
import PopupDefault from './PopupDefault.vue'
import PopupQuizMultiple from '../popups/PopupQuizMultiple.vue'
import PopupReferences from './PopupReferences.vue'
import PopupScore from './PopupScore.vue'

export default {
  name: 'BasePopup',
  components: {
    PopupDefault,
    PopupQuizMultiple,
    PopupReferences,
    PopupScore,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      key: null,
      popupTypes: {
        'default': 'PopupDefault',
        'references': 'PopupReferences',
        'score': 'PopupScore',
        'select-all-quiz': 'PopupQuizMultiple',
      },
      popupComponent: null,
    }
  },
  methods: {
    getStageType(type) {
      return this.popupTypes[type] || null;
    },
    showReferences() {
      this.$emit('action', { type: 'show-references', items: this.popup.references });
    },
    onAction(event) {
      this.$emit('action', event);
    }
  },
  created() {
    this.key = 1;
    this.popupComponent = this.getStageType(this.popup.type);
  },
}
</script>
  