<template>
  <div class="section-row section-heading">
    <p 
      v-for="(item, index) in data.value"
      :key="index"
      v-html="item"
    ></p>
  </div>
</template>

<script>
export default {
  name: 'RecordHeading',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
