<template>
  <div class="stage">
    <h1
      v-if="stage.heading"
      class="stage-heading"
      v-html="stage.heading"
    ></h1>

    <ButtonGroup
      ref="button-group"
      :buttons="options"
      :disableOnPress="true"
      @action="onAction"
    />

    <ButtonMedicalRecords v-if="stage['records-button']" @action="onAction" />  

    <BasePopup
      v-if="activePopup"
      :popup="activePopup"
      @action="onAction"
    />
  </div>
</template>

<script>
import BasePopup from '../popups/BasePopup.vue'
import ButtonGroup from '../partials/ButtonGroup.vue'
import ButtonMedicalRecords from '../partials/ButtonMedicalRecords.vue'

export default {
  name: 'StageAnyOptions',
  components: {
    BasePopup,
    ButtonGroup,
    ButtonMedicalRecords,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePopup : null,
      selections: 0,
      options: [],
      answers: [],

      eventHandlers: {
        'show-records': this.showRecords,
        'show-popup': this.showPopup,
        'close-popup': this.hidePopup,
        'calculate-score': this.noFurtherSelections,
      },
    }
  },
  methods: {
    updateScore() {
      let score = 0;
      this.options.forEach(item => {
        const selected = this.answers.indexOf(item.action.answer) > -1;
        const correct = this.stage.answers.indexOf(item.action.answer) > -1;
        score += (selected && !correct) || (!selected && correct) ? item.action.scoremod : 0;
      });
      this.$store.commit('subtractScore', score);
    },

    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },
    showRecords(event) {
      this.$emit('action', event);
    },
    showPopup(event) {
      if (!this.options[event.popup].disabled && !this.options[event.popup].static) {
        this.options[event.popup].disabled = true;
      }
      this.options[this.options.length - 1].hidden = false;
      this.activePopup = this.stage.popups[event.popup] || null;

      if (this.answers.indexOf(event.answer) < 0) {
        this.answers.push(event.answer);
      }
    },
    hidePopup() {
      if (this.selections === this.stage.count) {
        this.noFurtherSelections();
      }
      this.activePopup = null;
    },
    noFurtherSelections() {
      this.updateScore();
      this.$emit('action', this.stage.onended);
    },
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.stage.options));
  }
}
</script>
