<template>
  <div class="section-row section-image">
    <div class="section-image-container">
      <img :src="`./static/images/screens/${data.value}`" alt="...">
      <div 
        class="section-image-expand"
        @click.prevent="onClick()"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordButton',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('action', { type: 'show-image', popup: { popup: this.data.value, class: this.data.class || '' } });
    }
  },
}
</script>
