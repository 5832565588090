<template>
  <div :class="'stage stage-default ' + stage.heading">
    <h1
      v-if="stage.heading"
      v-html="stage.heading"
    ></h1>

    <ButtonGroup
      v-if="stage.options"
      ref="button-group"
      :buttons="stage.options"
      @action="onAction"
    />

    <ButtonMedicalRecords v-if="stage['records-button']" @action="onAction" />  

    <BasePopup
      v-if="activePopup"
      :popup="activePopup"
      @action="onAction"
    />
  </div>
</template>

<script>
import BasePopup from '../popups/BasePopup.vue'
import ButtonGroup from '../partials/ButtonGroup.vue'

export default {
  name: 'StageScore',
  components: {
    BasePopup,
    ButtonGroup,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePopup : null,
      score: 0,

      eventHandlers: {
        'goto-stage': this.gotoStage,
        'show-popup': this.showPopup,
        'reset-simulation': this.resetSimulation,
      },
    }
  },
  methods: {
    getPopup() {
      let popup = null;
      this.stage.scoring.forEach(element => {
        if (popup === null && this.score >= element.threshold) {
          popup = element.popup;
        }
      });
      return popup;
    },

    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },
    gotoStage(event) {
      this.activePopup = null;
      this.$emit('action', event);
    },
    showPopup() {
      const popup = this.getPopup();
      this.activePopup = this.stage.popups[popup] || null;
    },
    resetSimulation() {
      window.sessionStorage.setItem('CLARA_PHASE_PASS', JSON.stringify({
        phase: 2,
        score: this.score,
        pass: this.score >= this.stage.pass
      }));
      window.location.href = this.$store.getters.setting('home-url');
    },
  },
  mounted() {
    this.score = this.$store.getters.score;
    if (this.stage.onload) {
      this.onAction(this.stage.onload);
    }
  },
}
</script>
