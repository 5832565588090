<template>
  <div class="section-row">
    <p 
      v-for="(item, index) in data.value"
      :key="index"
      :class="[ { 'single-column': data.value.length === 1 || data.single } ]"
      v-html="item"
    ></p>
  </div>
</template>

<script>
export default {
  name: 'RecordText',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
