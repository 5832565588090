<template>
  <div :class="[ 'popup-inner', popup.class ]">
    <div>
      <h1
        v-if="popup.heading"
        v-html="$getString(popup.heading)"
      ></h1>

      <template
        v-for="(item, index) in popup.items"
      >
        <PopupList v-if="item.type === 'list'" :key="index" :items="item.value" :classes="item.class || ''" />
        <PopupText v-if="item.type === 'text'" :key="index" :lines="item.value" :classes="item.class || ''" />
        <PopupTextColumns v-if="item.type === 'text-columns'" :key="index" :columns="item.value" :classes="item.class || ''" />
      </template>
    </div>

    <div class="popup-buttons">
      <DefaultButton
        v-for="(button, index) in popup.buttons"
        :key="index"
        :label="button.label"
        labelClass="text-uppercase"
        type="popup"
        @pressed="onPressed(button)"
      />
    </div>

    <slot></slot>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton'
import PopupList from '../partials/PopupList'
import PopupText from '../partials/PopupText'
import PopupTextColumns from '../partials/PopupTextColumns'

export default {
  name: 'PopupDefault',
  components: {
    DefaultButton,
    PopupList,
    PopupText,
    PopupTextColumns,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onPressed(event) {
      this.$emit('action', event);
    }
  },
}
</script>
  