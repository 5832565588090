<template>
  <div class="stage stage-correct-pathway">
    <div class="popup background-popup">
      <div class="popup-inner">
        <div>
          <h1 v-html="$getString(stage.heading)"></h1>

          <div class="items">
            <template
              v-for="(item, index) in stage.items"
            >
            <div
              :key="index"
              v-if="item.type === 'tests'"
            >
              <p class="font-bold" v-html="item.heading"></p>
              <ul class="test-items flex flex-row align-items-center justify-content-space-between">
                <li
                  v-for="(test, testIndex) in item.items"
                  :key="testIndex"
                  :class="[ 'font-regular', test.classes ]"
                  v-html="test"
                ></li>
              </ul>
            </div>
            <div
              :key="index"
              v-else
              class="flex flex-row align-items-center justify-content-space-between"
            >
              <p class="font-bold" v-html="item.heading"></p>
              <ul 
                class="test-selection flex flex-row align-items-center justify-content-space-between"
              >
                <li
                  v-for="(test, testIndex) in item.items"
                  :key="testIndex"
                  :class="[ 'button button-default', test.classes ]"
                >
                  <div class="button-inner icon">
                    <div v-if="test.icon" :class="'icon-img icon icon-' + test.icon"></div>
                    <span v-html="test.label"></span>
                  </div>
                </li>
              </ul>
            </div>
            </template>
          </div>
        </div>

        <div class="popup-buttons">
          <DefaultButton
            label="BTN_END"
            labelClass="text-uppercase"
            type="popup"
            @pressed="resetSimulation()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton.vue'

export default {
  name: 'StageCorrectPathway',
  components: {
    DefaultButton,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resetSimulation() {
      const score = this.$store.getters.score;
      window.sessionStorage.setItem('CLARA_PHASE_PASS', JSON.stringify({
        phase: 2,
        score: score,
        pass: score >= this.stage.pass
      }));
      window.location.href = this.$store.getters.setting('home-url');
    },
  },
}
</script>
