<template>
  <div class="medical-records">
    <div class="popup background-popup">
      <div class="popup-inner">

        <div
          v-if="ready"
          :class="[ 'record-display', { blur: glossaryPopup > -1 || imagePopup } ]"
        >

          <header>
            <div class="patient-icon">
              <img src="~@/assets/images/patient.png" alt="...">
            </div>
            <p v-html="$getString('SECTION_MEDICAL_RECORDS_HEADING')"></p>

            <div class="screen-icons"></div>
            <div
              class="section-button glossary-button"
              v-html="$getString('SECTION_MEDICAL_RECORDS_BTN_GLOSSARY')"
              @click.prevent="showGlossary"
            ></div>
          </header>

          <div class="screen">
            <div class="screen-tabs">
              <div
                :class="[ 'new', { selected: position.screen === 0 } ]"
                v-html="$getString('SECTION_MEDICAL_RECORDS_NEW_CONSULTATION')"
                @click.prevent="setScreen(0)"
              ></div>
              <div
                :class="[ 'inner-tabs', { selected: position.screen > 0 } ]"
              >
                <div class="spacer" v-html="$getString('SECTION_MEDICAL_RECORDS_INVESTIGATIONS')"></div>
                <template v-for="(screen_tab, index) in screens">
                  <div
                    v-if="screen_tab.type !== 'fixed'"
                    :key="index"
                    :class="[
                      'screen-tab',
                      { disabled: available.indexOf(index) < 0 },
                      { selected: position.screen === index }
                    ]"
                    @click.prevent="setScreen(index)"
                    @action="onAction"
                  >
                    <span v-html="screen_tab.label"></span>
                  </div>
                </template>
              </div>
            </div>

            <div class="section">
              <div class="section-tabs">
                <div
                  v-for="(section_tab, index) in screen.tabs"
                  :key="index"
                  :class="[ 'section-tab', { selected: position.section === index } ]"
                  @click.prevent="setSection(index)"
                  @action="onAction"
                >
                  <span v-html="section_tab.label"></span>
                </div>
              </div>

              <div class="section-display">
                <component
                  v-for="(item, index) in section.data"
                  :is="getComponent(item.type)"
                  :key="index"
                  :data="item"
                  @action="onAction"
                />
              </div>
            </div>
          </div>

        </div>

        <div v-if="glossaryPopup > -1" class="screen-popup popup-glossary">
          <div class="screen-popup-inner">
            <h1 class="text-center fg-purple-mid font-size-45 mb-3" v-html="$getString('SECTION_MEDICAL_RECORDS_BTN_GLOSSARY')"></h1>
            <template
              v-for="(section, section_index) in glossary"
            >
              <div
                v-show="glossaryPopup === section_index"
                :key="section_index"
                class="glossary-content"
              >
                <p
                  v-for="(item, index) in section.items"
                  :key="index"
                  :class="[ { 'break-column': index === section.break } ]"
                  v-html="item"
                ></p>
              </div>
            </template>

            <div class="popup-buttons">
              <template v-for="(section, section_index) in glossary">
                <DefaultButton
                  v-if="section_index === glossaryPopup"
                  :key="section_index"
                  :label="getButtonLabel(section_index)"
                  labelClass="text-uppercase"
                  type="popup"
                  @pressed="glossaryPopup = glossaryPopup === 0 ? 1 : 0"
                />
              </template>
            </div>
            <div class="close" @click.prevent="hideGlossary"></div>
          </div>
        </div>

        <div v-if="imagePopup" class="screen-popup popup-image">
          <div class="screen-popup-inner">
            <img
              :class="imagePopup.class || ''"
              :src="`./static/images/screens/${imagePopup.popup}`" alt="...">
            <div class="close" @click.prevent="hideImage"></div>
          </div>
        </div>

      </div>
    </div>

    <div
      class="back-button"
      v-html="$getString('SECTION_MEDICAL_RECORDS_BTN_BACK')"
      @click.prevent="hideRecords"
    ></div>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton'
import RecordButton from './RecordButton'
import RecordHeading from './RecordHeading'
import RecordImage from './RecordImage'
import RecordList from './RecordList'
import RecordText from './RecordText'

export default {
  name: 'MedicalRecords',
  components: {
    DefaultButton,
    RecordButton,
    RecordHeading,
    RecordImage,
    RecordList,
    RecordText,
  },
  props: {
    start: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      position: {
        screen: 0,
        section: 0,
      },
      glossary: [],

      screens: [],
      available: [],

      screen: null,
      section: null,
      ready: false,

      glossaryPopup: -1,
      imagePopup: null,

      eventHandlers: {
        'show-image': this.showImage,
      },

      componentTypes: {
        'button': 'RecordButton',
        'heading': 'RecordHeading',
        'image': 'RecordImage',
        'list': 'RecordList',
        'text': 'RecordText',
      },
    }
  },
  methods: {
    setScreen(index) {
      if (this.available.indexOf(index) > -1) {
        this.position.screen = index;
        this.screen = this.screens[this.position.screen];
        this.section = this.screen.tabs[0];
        this.setSection(0);
      }
    },
    setSection(index) {
      this.position.section = index;
      this.section = this.screen.tabs[this.position.section];
      this.$forceUpdate();
    },
    getComponent(type) {
      return this.componentTypes[type];
    },
    getButtonLabel(index) {
      const key = index === 0 ? 'BTN_NEXT' : 'BTN_BACK';
      return this.$getString(key);
    },

    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },
    gotoStage(event) {
      this.activePopup = null;
      this.$emit('action', event);
    },
    hideRecords() {
      this.$emit('action', { type: 'hide-records' });
    },
    showPopup(event) {
      this.popup = event.popup;
    },
    showImage(event) {
      event = event.type ? event.popup : event;
      this.imagePopup = event;
    },
    hideImage() {
      this.imagePopup = null;
    },
    showGlossary() {
      this.glossaryPopup = 0;
    },
    hideGlossary() {
      this.glossaryPopup = -1;
    },
  },
  mounted() {
    let buffer = { screen: 0, section: 0 };
    if (this.start !== null) {
      buffer = this.start;
      this.position = JSON.parse(JSON.stringify(this.start));
      this.$store.commit('enableSections', this.position.screen);
    } else {
      this.position = { screen: 0, section: 0 };
    }

    this.available = this.$store.getters.availableSections;
    
    this.screens = this.$store.getters.screens;
    this.setScreen(buffer.screen);
    this.setSection(buffer.section);

    if (this.position.image) {
      this.showImage({ popup: this.position.image, class: this.position.class });
    }

    this.glossary = this.$store.getters.string('CONTENT_GLOSSARY');
    this.ready = true;
  },
}
</script>
