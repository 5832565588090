<template>
  <div
    v-if="pressed.length < buttons.length || !disableOnPress"
    class="button-group"
  >
    <template v-for="(button, index) in buttons">
      <DefaultButton
        v-if="!button.hidden"
        :key="index"
        :label="button.label"
        :buttonClass="buttonClass(index)"
        :icon="button.icon"
        :disabled="button.disabled"
        @pressed="onPressed(index, button.action)"
      />
    </template>
  </div>
</template>

<script>
import DefaultButton from './DefaultButton'

export default {
  name: 'ButtonGroup',
  components: {
    DefaultButton,
  },
  props: {
    buttons: {
      type: Array,
      required: false,
      default: () => [],
    },
    disableOnPress: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledClass: {
      type: String,
      required: false,
      default: 'inactive',
    },
  },
  data() {
    return {
      pressed: [],
    }
  },
  methods: {
    onPressed(index, action) {
      this.pressed.push(index);
      this.$emit('action', action);
    },
    buttonClass(index) {
      return this.isActive(index) ? '' : this.disabledClass;
    },
    isActive(index) {
      return !this.disableOnPress || this.pressed.indexOf(index) < 0;
    },
    reset() {
      this.pressed = [];
    },
  },
}
</script>
