<template>
  <div
    class="records-button"
    v-html="$getString('BTN_ACCESS_RECORDS')"
    @click.prevent="onClick"
  ></div>
</template>

<script>
export default {
  name: 'ButtonMedicalRecords',
  methods: {
    onClick() {
      this.$emit('action', { type: 'show-records' });
    }
  },
}
</script>
