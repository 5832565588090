<template>
  <div :class="[ 'popup-inner', popup.class ]">
    <template v-if="!error">
      <div>
        <h1
          v-if="popup.heading"
          v-html="$getString(popup.heading)"
        ></h1>
        <p
          v-if="popup.subheading"
          class="quiz-subheading"
          v-html="$getString(popup.subheading)"
        ></p>

        <div class="checkbox-list">
          <div
            v-for="(item, index) in popup.items"
            :key="index"
            :class="[ 'option', { selected: answers.indexOf(index) > -1 } ]"
            v-html="item"
            @click.prevent="selectItem(index)"
          ></div>
        </div>
      </div>

      <div class="popup-buttons">
        <DefaultButton
          v-for="(button, index) in popup.buttons"
          :key="index"
          :label="button.label"
          labelClass="text-uppercase"
          type="popup"
          @pressed="onAction(button)"
        />
      </div> 
    </template>

    <template v-else>
      <div class="error-container flex flex-column align-items-center justify-content-center">
        <div class="exclaimation"></div>
        <p class="text-center" v-html="error"></p>
      </div>

      <div class="popup-buttons">
        <DefaultButton
          label="BTN_BACK"
          labelClass="text-uppercase"
          type="popup"
          @pressed="clearError"
        />
      </div> 
    </template>

    <slot></slot>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton'

export default {
  name: 'PopupQuizMultiple',
  components: {
    DefaultButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answers: [],
      error: '',

      eventHandlers: {
        'check-answers': this.checkAnswers,
      },
    }
  },
  methods: {
    selectItem(index) {
      const answerIndex = this.answers.indexOf(index);
      if (answerIndex > -1) {
        this.answers.splice(answerIndex, 1);
      } else {
        this.answers.push(index);
      }
    },
    isComplete() {
      return this.popup.answers.length === this.answers.length;
    },
    isCorrect() {
      let correct = true;
      this.answers.forEach(index => {
        if (this.popup.answers.indexOf(index) < 0) {
          correct = false;
        }
      });
      return correct;
    },
    storeAnswers() {
      const answers = [];
      this.answers.sort().forEach(answer => {
        answers.push(this.popup.items[answer]);
      });
      this.$store.commit('setQuizAnswers', answers);
    },
    clearError() {
      this.error = '';
    },
    updateScore() {
      let score = 0;
      this.popup.items.forEach((item, index) => {
        const selected = this.answers.indexOf(index) > -1;
        const correct = this.popup.answers.indexOf(index) > -1;
        score += (selected && !correct) || (!selected && correct) ? this.popup.scoremod : 0;
      });
      this.$store.commit('subtractScore', score);
    },

    onAction(event) {
      console.log(event);
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },

    checkAnswers() {
      this.storeAnswers();
      this.updateScore();

      if (!this.isCorrect()) {
        this.error = this.popup.result.incorrect;
      } else if (!this.isComplete()) {
        this.error = this.popup.result.incomplete;
      } else {
        this.error = '';
        this.$emit('action', { type: 'show-popup', popup: 1 });
      }
    },
  },
}
</script>
  