<template>
  <div class="text-columns">
    <div v-for="(column, col_index) in columns" :key="col_index">
      <p v-for="(line, index) in column" :key="index" v-html="line"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupTextColumns',
  props: {
    columns: {
      type: Array,
      required: true,
    }
  },
}
</script>
