<template>
  <div class="page splash-screen flex flex-column align-items-center background-blurred">
    <DefaultButton
      label="BTN_START_CONSULTATION"
      labelClass="text-uppercase"
      @pressed="begin"
    />

    <img src="~@/assets/images/bms-logo.png" alt="..." class="bms-logo">

    <footer class="border-box flex flex-row align-items-center justify-content-space-between">
        <div>
          <p class="font-size-25 line-height-30 fg-white">
            <span v-html="$getString('GLOBAL_JOB_CODE')"></span>&nbsp;&nbsp;&nbsp;
            <span v-html="$getString('GLOBAL_DATE_OF_PREP')"></span>
          </p>
        </div>
      </footer>
  </div>
</template>

<script>
import DefaultButton from './partials/DefaultButton'

export default {
  name: 'SplashScreen',
  components: {
    DefaultButton,
  },
  methods: {
    begin() {
      this.$emit('action', { type: 'navigate', data: 'PatientIntroduction' });
    }
  },
  mounted() {
    this.$store.commit('initQuizAnswers');
    this.$store.commit('initFeedback');
    this.$store.commit('resetScore');
  },
}
</script>

<style lang="scss" scoped>
.splash-screen {
  padding-top: 48rem;

  .bms-logo {
    margin-top: 10.5rem;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    padding: 0 9.25rem 0 5rem;
  }
}
</style>
