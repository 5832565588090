<template>
  <div class="stage stage-feedback">
    <div class="popup background-popup">
      <div class="popup-inner">
        <div>
          <h1 v-html="$getString(stage.heading)"></h1>

          <div
            v-if="stage.key"
            class="key"
          >
            <h3 v-html="$getString(stage.key.heading)"></h3>
            <p v-html="$getString(stage.key.text)"></p>
          </div>

          <div class="questions">
            <div
              v-for="(question, index) in stage.options"
              :key="index"
              class="flex flex-row align-items-center justify-content-space-between"
            >
              <p v-html="question"></p>
              <ul :class="[ { selected: answered[index] > 0 } ]">
                <li 
                  v-for="choice in 5"
                  :key="choice"
                  v-html="choice"
                  :class="feedback[index] === choice ? 'selected' : ''"
                  @click.prevent="select(index, choice)"
                ></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="popup-buttons">
          <DefaultButton
            label="BTN_SUBMIT"
            labelClass="text-uppercase"
            type="popup"
            @pressed="submit()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton.vue'

export default {
  name: 'StageFeedback',
  components: {
    DefaultButton,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    answered() {
      return this.feedback;
    },
  },
  data() {
    return {
      feedback: [],

      eventHandlers: {
        'goto-stage': this.gotoStage,
        'show-popup': this.showPopup,
        'close-popup': this.hidePopup,
      },
    }
  },
  methods: {
    selectedClass(index) {
      return this.feedback[index] > -1 ? 'selected' : '';
    },

    select(question, choice) {
      this.$store.commit('setFeedback', question, choice);
      this.feedback[question] = choice;
      this.$forceUpdate();
    },

    submit() {
      this.$store.commit('initFeedback', this.feedback);
      this.$emit('action', { type: 'goto-stage', target: 'next'});
    },
  },
  created() {
    this.feedback = [];
    this.stage.options.forEach(() => this.feedback.push(-1));
    this.$store.commit('initFeedback', this.feedback);
  },
}
</script>
