<template>
  <div
    v-show="conditionMet"
    class="section-row section-heading section-button-container"
  >
    <div
      class="section-button"
      v-html="data.value"
      @click.prevent="onClick"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'RecordButton',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    conditionMet() {
      return !this.data.condition || this.$store.getters.condition(this.data.condition);
    }
  },
  methods: {
    onClick() {
      this.$emit('action', { type: 'show-image', popup: this.data });
    }
  },
}
</script>
