<template>
  <div class="page patient-diagnosis background-diagnosis">
    <component
      v-if="!loading"
      v-show="!medicalRecord"
      ref="stage"
      :key="stageKey"
      :is="activeStageType"
      :stage="activeStage"
      @action="onAction"
    />

    <MedicalRecords
      v-if="medicalRecord"
      :start="medicalRecord.start"
      @action="onAction"
    />

    <BasePopup
      v-if="references"
      :popup="references"
      @action="onAction"
    />
  </div>
</template>

<script>
import BasePopup from './popups/BasePopup.vue'

import StageAllOptions from './stages/StageAllOptions.vue'
import StageAnyOptions from './stages/StageAnyOptions.vue'
import StageCorrectOptions from './stages/StageCorrectOptions.vue'
import StageCorrectPathway from './stages/StageCorrectPathway.vue'
import StageDefault from './stages/StageDefault.vue'
import StageFeedback from './stages/StageFeedback.vue'
import StageScore from './stages/StageScore.vue'
import StageVideo from './stages/StageVideo.vue'
import StageVideoAll from './stages/StageVideoAll.vue'

import MedicalRecords from './screens/MedicalRecords.vue'

export default {
  name: 'DiagnosisStages',
  components: {
    BasePopup,
    StageAllOptions,
    StageAnyOptions,
    StageCorrectOptions,
    StageCorrectPathway,
    StageDefault,
    StageFeedback,
    StageScore,
    StageVideo,
    StageVideoAll,
    MedicalRecords,
  },
  data() {
    return {
      loading: true,

      stageTypes: {
        'default': 'StageDefault',
        'feedback': 'StageFeedback',
        'video': 'StageVideo',
        'video-all': 'StageVideoAll',
        'require-all-options': 'StageAllOptions',
        'score': 'StageScore',
        'select-correct-options': 'StageCorrectOptions',
        'correct-pathway': 'StageCorrectPathway',
        'select-any-options': 'StageAnyOptions',
      },
      activeStageType: null,
      activeStageIndex: 0,
      activeStage: null,
      stageKey: -1,

      medicalRecord: null,
      references: null,

      eventHandlers: {
        'goto-stage': this.gotoStage,
        'reset-stage': this.resetStage,
        'show-records': this.showMedicalRecords,
        'hide-records': this.hideMedicalRecords,
        'show-references': this.showReferences,
        'hide-references': this.hideReferences,
      },
    }
  },
  methods: {
    setStage(index) {
      this.loading = true;
      this.activeStage = this.$store.getters.stage(index);
      this.activeStageType = this.getStageType(this.activeStage.type);
      this.activeStageIndex = index;
      this.stageKey = Date.now();
      this.loading = false;
    },
    getStageType(type) {
      return this.stageTypes[type] || null;
    },
    getStageIndex(target) {
      if (target === 'next') {
        return this.activeStageIndex + 1;
      } else if (target === 'prev') {
        return this.activeStageIndex - 1;
      }
      return target;
    },

    // Actions
    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },
    resetStage() {
      this.setStage(this.activeStageIndex);
    },
    gotoStage(event) {
      const stageIndex = this.getStageIndex(event.target);
      this.setStage(stageIndex);
    },
    showMedicalRecords(event) {
      if (event.condition) {
        this.$store.commit('setCondition', { key: event.condition, value: true });
      }
      this.medicalRecord = event;
    },
    hideMedicalRecords() {
      const onComplete = this.medicalRecord.ended;
      this.medicalRecord = null;

      if (onComplete) {
        this.$refs['stage'].onAction(onComplete);
      }
    },
    showReferences(event) {
      this.references = {
        type: 'references',
        items: event.items,
      };
    },
    hideReferences() {
      this.references = null;
    },
  },
  created() {
    this.setStage(0);
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/components/variables";
</style>
