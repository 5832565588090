<template>
  <div class="popup-inner popup-references">
    <div>
      <h2
        v-html="$getString('HDG_REFERENCES')"
      ></h2>

      <ol class="references">
        <li
          v-for="(reference, index) in items"
          :key="index"
          v-html="reference"
        ></li>
      </ol>
    </div>

    <div class="popup-buttons">
      <DefaultButton
        :label="$getString('BTN_CLOSE')"
        labelClass="text-uppercase"
        type="popup"
        @pressed="onPressed"
      />
    </div>
  </div>
</template>

<script>
import DefaultButton from '../partials/DefaultButton'

export default {
  name: 'PopupReferences',
  components: {
    DefaultButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      return this.$store.getters.references(this.popup.items);
    }
  },
  methods: {
    onPressed() {
      this.$emit('action', { type: 'hide-references' });
    }
  },
}
</script>
  