<template>
  <div class="stage">
    <h1
      v-if="stage.heading"
      class="stage-heading"
      v-html="stage.heading"
    ></h1>

    <ButtonGroup
      ref="button-group"
      :buttons="stage.options"
      @action="onAction"
    />

    <ButtonMedicalRecords v-if="stage['records-button']" @action="onAction" />  

    <BasePopup
      v-if="activePopup"
      :popup="activePopup"
      @action="onAction"
    />
  </div>
</template>

<script>
import BasePopup from '../popups/BasePopup.vue'
import ButtonGroup from '../partials/ButtonGroup.vue'
import ButtonMedicalRecords from '../partials/ButtonMedicalRecords.vue'

export default {
  name: 'StageCorrectOptions',
  components: {
    BasePopup,
    ButtonGroup,
    ButtonMedicalRecords,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePopup : null,
      popupIndex: -1,
      answers: [],
      options: [],

      eventHandlers: {
        'show-records': this.showRecords,
        'show-popup': this.showPopup,
        'close-popup': this.hidePopup,
      },
    }
  },
  methods: {
    checkAnswers() {
      let correct = true;
      this.stage.answers.forEach(element => {
        if (this.answers.indexOf(element) < 0) {
          correct = false;
        }
      });
      return correct;
    },

    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },
    showRecords(event) {
      this.$emit('action', event);
    },
    showPopup(event) {
      if (this.answers.indexOf(event.popup) < 0) {
        this.answers.push(event.popup);
      }
      
      this.activePopup = this.stage.popups[event.popup] || null;
      this.popupIndex += 1;
    },
    hidePopup() {
      this.activePopup = null;
      if (this.checkAnswers()) {
        this.$emit('action', { type: 'goto-stage', target: 'next' });
      }
    },
  },
}
</script>
