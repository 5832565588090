<template>
  <div>
    <p 
      v-for="(line, index) in lines"
      :key="index"
      :class="classes"
      v-html="getString(line)"
    ></p>
  </div>
</template>

<script>
export default {
  name: 'PopupText',
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    lines: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getString(key) {
      let string = this.$getString(key);

      if (string.indexOf('{SCORE}') > -1) {
        const score = this.$store.getters.score;
        string = string.replace('{SCORE}', score);
      }

      return string;
    },
  },
}
</script>
