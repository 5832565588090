<template>
  <div class="stage stage-video">
    <VideoWrapper
      v-if="activeVideo"
      ref="video"
      :source="activeVideo.src"
      :chapter="activeVideo.chapter"
      @ended="onEnded"
      @action="onAction(activeVideo.ended)"
    />

    <h1
      v-if="stage.heading"
      v-html="stage.heading"
    ></h1>

    <ButtonGroup
      v-if="stage.options.length > 0"
      v-show="!playing"
      ref="button-group"
      :buttons="stage.options"
      @action="onAction"
    />

    <ButtonMedicalRecords v-if="stage['records-button']" v-show="!playing" @action="onAction" />

    <BasePopup
      v-if="activePopup"
      :key="popupIndex"
      :popup="activePopup"
      @action="onAction"
    />
  </div>
</template>

<script>
import BasePopup from '../popups/BasePopup.vue'
import ButtonGroup from '../partials/ButtonGroup.vue'
import ButtonMedicalRecords from '../partials/ButtonMedicalRecords.vue'
import VideoWrapper from '../partials/VideoWrapper.vue'

export default {
  name: 'StageVideo',
  components: {
    BasePopup,
    ButtonGroup,
    ButtonMedicalRecords,
    VideoWrapper,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePopup : null,
      popupIndex: -1,

      videoBuffer: null,
      activeVideo: null,
      disableOnPlay: true,
      playing: false,

      eventHandlers: {
        'goto-stage': this.gotoStage,
        'on-ended': this.onEnded,
        'play-video': this.playVideo,
        'pause-video': this.pauseVideo,
        'show-records': this.showRecords,
        'show-popup': this.showPopup,
        'close-popup': this.hidePopup,
      },
    }
  },
  methods: {
    reset() {
      this.$refs['button-group'].reset();
      this.$refs['video'].reset();
    },

    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      } else {
        this.$emit('action', event);
      }
    },
    gotoStage(event) {
      this.activePopup = null;
      this.$emit('action', event);
    },
    onEnded() {
      this.playing = false;
    },
    playVideo(event) {
      this.activeVideo = null;
      this.activeVideo = event;
      this.playing = true;
    },
    pauseVideo() {
      if (this.activeVideo) {
        this.$refs['video'].pause();
      }
    },
    showRecords(event) {
      this.pauseVideo();
      this.$emit('action', event);
    },
    showPopup(event) {
      this.activePopup = this.stage.popups[event.popup] || null;
      this.popupIndex += 1;
    },
    hidePopup(event) {
      this.activePopup = null;

      if (event.reset) {
        this.reset();
      }
    },
  },
  mounted() {
    if (this.stage.onload) {
      this.disableOnPlay = false; // Temporary fix.
      this.onAction(this.stage.onload);
    }
  },
}
</script>
