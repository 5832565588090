<template>
  <div class="video-wrapper">
    <video
      ref="video"
      :src="`${videoPath}/${source}`"
      :autoplay="autoplay"
      playsinline
      @timeupdate="onTimeUpdate"
      @ended="onEnded"
    ></video>
  </div>
</template>

<script>
export default {
  name: 'VideoWrapper',
  props: {
    source: {
      type: String,
      required: true,
    },
    chapter: {
      type: Object,
      required: false,
      default: null,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    videoPath() {
      return this.$store.getters.setting('video-path');
    }
  },
  methods: {
    onTimeUpdate(event) {
      if (this.chapter && 
          this.chapter.end > -1 && 
          event.target.currentTime >= this.chapter.end)
      {
        this.pause();
        this.onEnded();
      }
    },
    reset() {
      this.$refs['video'].currentTime = 0;
    },
    pause() {
      if (!this.$refs['video'].paused) {
        this.$refs['video'].pause();
      }
    },
    play() {
      this.$refs['video'].play();
    },
    onEnded() {
      this.$emit('ended');
      this.$emit('action');
    }
  },
  mounted() {
    if (this.chapter !== null) {
      this.pause();
      this.$refs['video'].currentTime = this.chapter.start;
      this.play();
    }
  },
}
</script>
  