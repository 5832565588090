<template>
  <div class="page patient-introduction background">
    <template v-if="!popupState">
      <section class="introduction flex flex-column align-items-center">
        <h1
          class="fg-purple-mid font-size-45 line-height-42 mt-13"
          v-html="$getString('HDG_WELCOME')"
        ></h1>
        <div
          class="introduction-text fg-grey-dark text-center font-size-30 line-height-37 my-7"
          v-html="$getString('SECTION_MEET_CLARA')"
        ></div>
        <DefaultButton
          label="BTN_START_CONSULTATION"
          labelClass="text-uppercase"
          @pressed="showInstructions"
        />
        <p
          class="fg-grey-dark text-center font-semibolditalic font-size-20 line-height-24 mt-7"
          v-html="$getString('SECTION_MEET_CLARA_DISCLAIMER')"
        ></p>
      </section>
      <footer class="border-box flex flex-row align-items-center justify-content-space-between">
        <div>
          <p
            class="font-semibold font-size-26 line-height-39 fg-white mb-2"
            v-html="$getString('DISCLAIMER_USE')"
          ></p>
          <p class="font-size-25 line-height-30 fg-white">
            <span v-html="$getString('GLOBAL_JOB_CODE')"></span>&nbsp;&nbsp;&nbsp;
            <span v-html="$getString('GLOBAL_DATE_OF_PREP')"></span>
          </p>
        </div>
        <img src="~@/assets/images/bms-logo.png" alt="..." class="bms-logo mb-4">
      </footer>
    </template>
    <div v-else class="popup background-blurred">
      <section class="popup-inner">
        <div>
          <h1
            v-html="$getString('HDG_INSTRUCTIONS')"
          ></h1>
          <div
            class="fg-white font-regular font-size-35 line-height-46 mb-12"
            v-html="$getString('POPUP_INSTRUCTIONS')"
          ></div>
          <p
            class="fg-white font-bolditalic font-size-32 line-height-42"
            v-html="$getString('POPUP_INSTRUCTIONS_DISCLAIMER')"></p>
        </div>

        <div class="popup-buttons">
          <DefaultButton
            label="BTN_START_CONSULTATION"
            labelClass="text-uppercase"
            type="popup"
            @pressed="begin"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DefaultButton from './partials/DefaultButton'

export default {
  name: 'PatientIntroduction',
  components: {
    DefaultButton,
  },
  data() {
    return {
      popupState: false,
    }
  },
  methods: {
    showInstructions() {
      this.popupState = true;
    },
    begin() {
      this.$emit('action', { type: 'navigate', data: 'DiagnosisStages' });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/components/variables";
.patient-introduction {
  .introduction {
    position: absolute;
    top: 0;
    left: 5.5rem;
    width: 53.5rem;
    height: 83.6rem;
    border-radius: 0 0 7.5rem 7.5rem;
    overflow: hidden;
    background: transparent url(~@/assets/images/background-blur.png) 0 0 no-repeat;
    background-size: contain;

    div, p, h1 {
      z-index: 1;
    }
  }
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 19rem;
    padding: 0 9.25rem 0 5rem;
    border-top: 0.5rem solid map-get($colors, purple);
    background-color: rgba(36, 26, 33, 0.9);
  }
}
</style>
