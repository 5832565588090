<template>
  <div id="app">
    <component
      v-if="loaded"
      :is="activeComponent"
      @action="onAction"
    />
  </div>
</template>

<script>
import SplashScreen from './components/SplashScreen.vue'
import PatientIntroduction from './components/PatientIntroduction.vue'
import DiagnosisStages from './components/DiagnosisStages.vue'

export default {
  name: 'App',
  components: {
    SplashScreen,
    PatientIntroduction,
    DiagnosisStages,
  },
  data() {
    return {
      loaded: false,
      activeComponent: 'SplashScreen',

      eventHandlers: {
        'navigate': this.onNavigate,
      }
    }
  },
  methods: {
    initStore(data) {
      this.$store.commit('setSettings', data.settings);
      this.$store.commit('setStrings', data.strings);
      this.$store.commit('setScreens', data.screens);
      this.$store.commit('setStages', data.stages);
      this.$store.commit('setReferences', data.references);

      this.loaded = true;
    },

    // Actions
    onAction(event) {
      if (event.type in this.eventHandlers) {
        this.eventHandlers[event.type](event);
      }
    },
    onNavigate(event) {
      this.activeComponent = event.data;
    },
  },
  created() {
    fetch('static/config.json')
      .then(response => response.json())
      .then(data => this.initStore(data));
  }
}
</script>

<style lang="scss">
@import "./scss/index";
</style>
